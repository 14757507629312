<!--
 * @Description: 规则 - 历史记录
 * @Author: kecraft
 * @Date: 2024-06-11 13:58:57
 * @LastEditors: kecraft
 * @LastEditTime: 2024-06-20 15:16:48
 * @FilePath: /impact-iotos-console/src/views/rules/history/index.vue
-->
<template>
  <div class="rule-history">
    <div class="breadcrumb-conatiner">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/rules/list' }">规则引擎</el-breadcrumb-item>
        <el-breadcrumb-item>历史发送记录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="bottom-box">
      <div class="search-container">
        <div class="form-item">
          <div class="is-title">记录状态</div>
          <el-select v-model="query.status" class="width500" @change="search" clearable>
            <el-option :key="1" label="成功" :value="1" />
            <el-option :key="0" label="失败" :value="0" />
          </el-select>
        </div>
        <div class="form-item">
          <span class="is-title">选择时间：</span>
          <el-date-picker v-model="times" type="datetimerange" range-separator="至" start-placeholder="开始时间"
            end-placeholder="结束时间" @change="search" />
        </div>
        <div class="form-item">
          <div class="search-btn" @click="search" v-domPreventReClick>搜索</div>
        </div>
        <div class="form-item">
          <div class="reset-btn" v-domPreventReClick @click="reset">重置</div>
        </div>
      </div>
      <div class="tables">
        <el-table stripe :data="historyList" empty-text="暂无数据" v-loading="isload" style="width: 100%;">
          <el-table-column width="80" label="序号" align="center">
            <template #default="scope">
              <span>{{
          scope.$index + 1
        }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="240" label="消息内容" prop="payload" align="center">
            <template #default="scope">
              <div v-if="!scope.row.payload || scope.row.payload.length < 40">{{ scope.row.payload }}</div>
              <div v-else>
                <div :class="!scope.row.more ? 'is-box' : ''">{{ scope.row.payload }}</div>
                <img src="@/assets/down-icon.png" class="is-img" @click="() => scope.row.more = true"
                  v-if="!scope.row.more" />
                <img src="@/assets/up-icon.png" class="is-img" @click="() => scope.row.more = false" v-else />
              </div>
            </template>
          </el-table-column>
          <el-table-column width="120" label="是否发送成功" prop="lastTriggerTime" align="center">
            <template #default="scope">
              {{ scope.row.status === 1 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column min-width="160" label="发送时间" prop="lastTriggerTime" align="center" show-overflow-tooltip />
          <el-table-column width="120" label="重试次数" prop="retryCount" align="center" />
          <el-table-column min-width="160" label="下次重试时间" prop="nextTriggerTime" align="center" />
          <el-table-column min-width="160" label="失败原因" prop="latestFailReason" align="center" />
          <el-table-column width="160" label="操作" align="center">
            <template #default="scope">
              <div class="column-btn">
                <div class="options">
                  <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleClick(scope.row)"
                    v-domPreventReClick v-if="scope.row.status === 0">
                    重发消息
                  </span>
                  <span link style="color:#B7BBC0;cursor: not-allowed;padding: 0 6px;" v-domPreventReClick v-else>
                    重发消息
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="fooner-conatiner">
        <pagination :total="total" @pageChange="getList" class="pagination" />
      </div>
    </div>
  </div>
</template>

<script setup>
import pagination from "@/components/control/pagination";
import moment from "moment";
import { onMounted, reactive, ref } from 'vue';
import { useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import api from "@/api/api"
const route = useRoute()
const total = ref(0)
const rid = ref("");
const times = ref([]);
const query = reactive({
  ruleId: "",
  status: "",
  page: 0,
  size: 10,
  startDate: "",
  endDate: ""
})

const reset = () => {
  times.value = [];
  query.startDate = query.endDate = query.status = "";
  search();
}
onMounted(() => {
  const { ruleId } = route.query;
  rid.value = ruleId;
  if (!ruleId) return;
  search()
})
const historyList = ref([]);
const isload = ref(false);
const search = () => {
  isload.value = true;
  if (times.value && times.value.length === 2) {
    query.startDate = moment(times.value[0]).format('YYYY-MM-DD')
    query.endDate = moment(times.value[1]).format('YYYY-MM-DD')
  } else {
    query.startDate = query.endDate = "";
  }
  api.getRulesHistoryByRuleId(rid.value, query).then(res => {
    if (res.code === "0") {
      const { totalElements, content } = res.res.data;
      total.value = totalElements;
      historyList.value = content;
      isload.value = false;
    }
  })
}
const handleClick = (row) => {
  const { messageId } = row;
  ElMessageBox.confirm("确定重新发送吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    api.ruleMessageRetry(messageId).then(res => {
      if (res.code === "0") {
        ElMessage.success('重发成功！');
        search()
      }
    })
  })

}
const getList = (v) => {
  query.page = v.pageNum - 1
  query.size = v.pageSize
  search();
}
</script>

<style lang="less" scoped>
.is-img {
  width: 11px;
  cursor: pointer;
}

.is-box {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rule-history {
  box-sizing: border-box;

  .breadcrumb-conatiner {
    box-sizing: border-box;
    padding: 20px 28px;
    border-bottom: 1px solid #E9ECF0;
  }

  .bottom-box {
    box-sizing: border-box;
    padding: 18px 40px;
  }

  .search-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    // justify-content: space-between;
    align-items: center;

    .is-title {
      display: inline-block;
      font-size: 16px;
      color: #606266;
      margin-right: 14px;
    }

    .form-item {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }

    .input-item {
      width: 260px;
    }
  }

  .fooner-conatiner {
    box-sizing: border-box;
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
  }
}

.search-btn {
  cursor: pointer;
  width: 112px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  box-sizing: border-box;
  background: #367CC5;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;
}

.search-btn:hover,
.search-btn:active {
  background: #015ee0;
  color: #fff;
}

.reset-btn {
  cursor: pointer;
  width: 112px;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: lighter;
  border: 1px solid #AFB9CB;
  color: #333333;
  font-size: 16px;
}


.tables {
  margin-top: 20px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 50px;
  font-weight: normal;
  color: #000000;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 16px;
  line-height: 50px;
}
</style>